//import defaulttheme from "./default";
//import tinycolor from "tinycolor2";

import {createMuiTheme} from "@material-ui/core";
/*
import {lighten} from '@material-ui/core/styles';
import {lightBlue} from "@material-ui/core/colors";
import {grey} from "@material-ui/core/colors";

//import { lightBlue } from "@material-ui/core/colors";

const primary = "#17202A";
const secondary = '#FAFAFA'

const purple = 'rgb(118, 94, 242)'
const blue = 'rgb(71, 203, 246)'
const yellow = 'rgb(244, 221, 103)'
const green  = 'rgb(56, 212, 160)'
const pink = 'rgb(233, 62, 100)'
const orange = 'rgb(243, 127, 87)'
const darkpurple = 'rgb(100, 64, 111)'


const purple1 = 'rgb(163, 156, 203)'

const warning = "#d50000";
const success = "#3CD4A0";
//const info = "#9013FE";
const info = blue;


const background = 'rgb(249, 249, 250)'

const lightenRate = 9.5;
const darkenRate = 15;
*/


// THESE OVERRIDES ARE THOSE THAT WORK
const overrides = {
    overrides: {
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: 'black',
                    borderWidth: '2px'
                },
                "&$focused $notchedOutline": {
                    borderColor: 'black',
                },
            },
            notchedOutline: {}
        },
        MuiDrawer: { 
            paper: {
                width: '100%',
                background: 'black',
                color: 'white'
            }
        },
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            },
            gutters: {
                paddingLeft: 0
            }

        }
    },
    typography: { /* https://material-ui.com/customization/typography/ */
        fontSize: 14, /* default font size */
        fontFamily: [
            //'Roboto',
            'Source Sans Pro',
            'sans-serif',
            '-system-ui',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Helvetica',
            'Arial',
        ].join(','),
    },
};

const theme = {
  default: createMuiTheme({ ...{}, ...overrides}),
}

export default theme 


