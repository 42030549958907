import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from "@material-ui/styles";
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import Themes from "./theme";
import { QueryClient, QueryClientProvider } from 'react-query'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';

//const DisplayContext = React.createContext()
//DisplayContext.displayName = 'DisplayContext'

const queryClient = new QueryClient()

const MobileApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ './mobile-app'),
)

const DesktopApp = React.lazy(() => import('./desktop-app'))

//const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
/*

const DisplayContext = React.createContext()

function countReducer(state, action) {
  switch (action.type) {
    case 'increment': {
      return {count: state.count + 1}
    }
    case 'decrement': {
      return {count: state.count - 1}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function DisplayProvider({children}) {
  const [state, dispatch] = React.useReducer(countReducer, {count: 0})
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch}
  return <DisplayContext.Provider value={value}>{children}</DisplayContext.Provider>
}

function useDisplay() {
  const context = React.useContext(DisplayContext)
  if (context === undefined) {
    throw new Error('useDisplay must be used within a DisplayProvider')
  }
  return context
}
*/

function App() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    return isDesktop? <DesktopApp/> : <MobileApp/>
}

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={Themes.default}>
            <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<CircularProgress size="20"/>}>
                <App />
        </React.Suspense>
            </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
